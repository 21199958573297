@keyframes cr {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
}

.gemCircle {
    animation: cr 20s linear infinite; 
}


@keyframes gr {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}

.gemImage {
  animation: gr 20s linear infinite; 
}
